import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo.component"
import { Menu } from "../../navigation/types/menu.interface"
import {
  AppFunctionComponent,
  BuilderSection,
  HeroBuilder,
  TextBuilder,
} from "../../types"
import { StandardPageResolver } from "../../templates/page.template"
import PageMetadata from "../../components/seo/page-metadata.interface"

export type NotFoundProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
    page: {
      id: string
      path: string
      title: string
      darkMode: boolean
      root: boolean
      meta: PageMetadata
      hero: HeroBuilder
      sections: BuilderSection[]
    }
  }
}

const KompaktEs: AppFunctionComponent<NotFoundProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
    page,
  },
}) => {
  const { hero } = page
  return (
    <Layout
      promobarContent={promobarContent}
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
    >
      <SEO {...page.meta} />
      <StandardPageResolver hero={hero} sections={page.sections} />
    </Layout>
  )
}

export const query = graphql`
  query KompaktQueryFR($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "en-US" }
    ) {
      text {
        raw
      }
      node_locale
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    page: contentfulPageBuilder(path: { eq: "/fr/kompakt" }) {
      id
      title
      path
      root
      darkMode
      meta: metaTags {
        ...PageMeta
      }
      hero {
        ...HeroBuilder
        ...Slider
      }
      sections {
        ...Text
        ...TextWithEmbed
        ...List
        ...NewsletterSection
        ...Slider
        ...CustomComponent
      }
    }
  }
`

export default KompaktEs
